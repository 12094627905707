import { json, redirect } from "@remix-run/node";
import type { LoaderFunction, ActionFunctionArgs } from "@remix-run/node";
import type { ShouldRevalidateFunction } from "@remix-run/react";
import {
  Form,
  Link,
  useActionData,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EnvelopeOpenIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { StyledTextInput } from "~/components/core";
import { login, TimeAttackDefender } from "~/lib/password.server";
import { Transition } from "@headlessui/react";
import type { MouseEvent } from "react";
import { useEffect, useState } from "react";
import invariant from "tiny-invariant";
import rateLimit from "~/lib/rate-limit.server";
import { LoginProvider } from "@prisma/client";
import prisma from "~/lib/prisma.server";
import { createUserSession, getUserId } from "~/lib/session.server";
import apps from "~/lib/apps";
import envsServer from "~/envs.server";
import CenteredLayout from "~/components/CenteredLayout";
import { sendWelcomeEmail } from "~/lib/user.server";

// do not reload loader data if we stay on this page
export const shouldRevalidate: ShouldRevalidateFunction = ({ formAction }) => {
  return formAction !== "/auth/login";
};

const badRequest = (message: string) => json({ error: message }, 400);

export const loader: LoaderFunction = async ({ request }) => {
  const userId = await getUserId(request);
  const referer = request.headers.get("referer");
  const requestUrl = new URL(request.url);
  const search = requestUrl.searchParams;
  const clientId = search.get("client_id");
  let requestedRedirect = search.get("redirect") || referer;

  // throw if redirect and referer are not the same
  if (clientId) {
    if (!requestedRedirect) {
      throw badRequest("redirect param or referer header is required");
    }

    if (referer) {
      const refererUrl = new URL(referer);
      // if (refererUrl.host !== redirectUrl.host) {
      //   throw badRequest("Referer does not match redirect");
      // }

      if (
        refererUrl.origin === requestUrl.origin &&
        envsServer.NODE_ENV === "production"
      ) {
        throw badRequest(
          "Redirect loop: referer and request origin are the same"
        );
      }
    }

    if (!clientId) {
      throw badRequest("Missing client_id");
    }

    const matchedApp = apps[clientId];
    // throw if client id is not found
    if (!matchedApp) {
      throw badRequest("Invalid client_id");
    }

    if (
      envsServer.DEPLOY_ENV === "production" &&
      !request.url.startsWith("http://localhost:3000/") &&
      !requestedRedirect.startsWith(matchedApp.url)
    ) {
      throw badRequest("Redirect URL not allowed");
    }

    // if user is already logged in, redirect now
    if (userId) {
      if (requestedRedirect) return redirect(requestedRedirect);
    }

    search.set("redirect", requestedRedirect);

    return {
      appRedirect: clientId,
      redirect: requestedRedirect,
      search: search.toString(),
      showDevWarning: envsServer.DEPLOY_ENV !== "production",
    };
  } else {
    requestedRedirect = search.get("redirectTo");
    // check that it is relative
    if (requestedRedirect && !requestedRedirect.startsWith("/")) {
      throw badRequest("Redirect URL must be relative");
    }
  }

  // if user is already logged in, redirect to home
  if (userId) {
    return redirect("/");
  }

  // normal login, no "app" redirect
  return {
    showDevWarning: envsServer.DEPLOY_ENV !== "production",
    redirect: requestedRedirect,
  };
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const data = await request.formData();
  const defender = new TimeAttackDefender();

  const email = data.get("email")?.toString().trim().toLowerCase();
  if (!email) {
    throw badRequest("Email is required.");
  }
  invariant(typeof email === "string", "Email must be a string");

  const action = data.get("_action");
  if (action === "password-login") {
    try {
      await rateLimit(request, { key: "pw-login" });
    } catch (e) {
      return json(
        {
          error: "Too many login attempts. Try again later.",
          email,
          provider: LoginProvider.PASSWORD,
        },
        429
      );
    }
    const password = data.get("password");
    if (!password) {
      return json(
        {
          error: "Password is required.",
          email,
          provider: LoginProvider.PASSWORD,
        },
        400
      );
    }
    invariant(typeof password === "string", "Password must be a string");
    const user = await login(email, password);
    if (user instanceof Response) return user;
    invariant("uid" in user, "User must have an uid");

    const redirect = data.get("redirect")?.toString();
    return createUserSession(user.uid, redirect || "/");
  }

  try {
    await rateLimit(request, { key: "login-query" });
  } catch (e) {
    return json(
      {
        error: "Too many login attempts. Try again later.",
        redirect: null,
      },
      429
    );
  }

  const user = await prisma.user.findUnique({
    where: { email },
    select: {
      email: true,
      uid: true,
      id: true,
      disabled: true,
      password: true,
      welcomeMailSendAt: true,
      name: true,
    },
  });
  await defender.wait();

  if (user?.disabled) return json({ error: "Your account is disabled." }, 403);

  if (user?.uid.startsWith("PASSWORD:") && !user.password) {
    // this only sends every 4 hours at most
    await sendWelcomeEmail(user);
    return json({
      showWelcome: true,
      redirect: null,
      email,
    });
  }

  // query case (user did not login yet)
  if (
    email.endsWith("@hydra-newmedia.com") ||
    user?.uid.startsWith("HYDRA_GITLAB:")
  ) {
    return json({
      email,
      provider: LoginProvider.HYDRA_GITLAB,
      redirect: data.get("redirect")?.toString(),
    });
  }

  return json({
    email,
    provider: LoginProvider.PASSWORD,
    redirect: data.get("redirect")?.toString(),
    error: null,
  });
};

const ClearableEmail = ({ email }: { email: string }) => {
  const reload = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.reload();
  };
  return (
    <div className="relative">
      <a
        href="/auth/login"
        className="absolute inset-y-0 right-0 flex items-center pr-3"
        onClick={reload}
      >
        <XMarkIcon className="w-4" />
      </a>
      <StyledTextInput
        type="email"
        value={email}
        name="email"
        id="login-email"
        placeholder="E-mail address"
        autoComplete="email"
        disabled
      />
    </div>
  );
};

const GitlabLogin = ({
  email,
  redirect,
}: {
  email: string;
  redirect: string | null;
}) => {
  return (
    <>
      <ClearableEmail email={email} />
      <Form
        method="post"
        action="/auth/oauth/gitlab"
        className="mt-4 flex items-center justify-between"
      >
        {redirect && <input type="hidden" name="redirect" value={redirect} />}
        <button
          type="submit"
          className="flex w-full items-center rounded border border-slate-100 bg-white p-2 px-5 text-black shadow-md ring-blue-300 focus:ring"
        >
          <img
            className="-mb-2 -mt-2 mr-2 w-10"
            src="/tools/gitlab-icon-rgb.svg"
            alt=""
          />
          Sign in with hydra GitLab
        </button>
      </Form>
    </>
  );
};

const PasswordLogin = ({
  email,
  redirect,
  onInput,
}: {
  email: string;
  redirect: string | null;
  onInput: () => void;
}) => {
  const transition = useNavigation();

  return (
    <Form className="space-y-3" method="post">
      <ClearableEmail email={email} />
      <input type="hidden" name="_action" value="password-login" />
      <input type="hidden" name="email" value={email} />
      {redirect && <input type="hidden" name="redirect" value={redirect} />}
      <StyledTextInput
        autoFocus
        type="password"
        name="password"
        id="login-password"
        placeholder="Password"
        autoComplete="current-password"
        required
        onKeyUp={onInput}
      />
      <button
        type="submit"
        disabled={transition.state !== "idle"}
        className="button-primary my-4 w-full"
      >
        Log In
      </button>
      <div>
        <Link
          to={`/auth/forgot-password?email=${email}`}
          className="my-8 text-blue-600 opacity-70 hover:text-blue-900 lg:my-10"
        >
          <small>Forgot password?</small>
        </Link>
      </div>
    </Form>
  );
};

const AppLoginNote = ({ app }: { app: keyof typeof apps }) => {
  if (!app) return null;
  const appData = apps[app];
  if (!appData) return null;

  if (!appData.name) {
    return <div className="flex justify-center">Sign in to continue.</div>;
  }

  if (!appData.icon) {
    return (
      <div className="flex justify-center">
        Sign in to continue to {appData.name}.
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center gap-2">
      <span>Sign in to continue to</span>
      <div className="flex gap-2 rounded-lg bg-slate-100 p-2 px-3">
        <img
          className="mr-2 h-6 w-6 rounded"
          src={appData.icon.src}
          alt={appData.name}
        />
        {appData.name}
      </div>
    </div>
  );
};

const DevWarning = () => {
  return (
    <div className="m-3 rounded border-orange-600 bg-orange-200 p-3 text-orange-900">
      <div className="flex items-center justify-center gap-2 text-xl">
        <ExclamationTriangleIcon className="mr-2 h-8 w-8" />
        <b>Testing environment</b>
      </div>
      <div className="mt-2">
        <p>
          This is a development environment. Some things might not work as
          expected.
          <br />
        </p>
        <small>Only continue if instructed to do so.</small>
      </div>
    </div>
  );
};

// first time login message
function Welcome({ email }: { email: string }) {
  return (
    <CenteredLayout>
      <div className="p-6 py-8">
        <h2 className="mb-2 text-xl lg:text-2xl">Welcome to hydra projects!</h2>
        <div className="flex items-center justify-center">
          <div className="mb-4 rounded-full bg-slate-100">
            <EnvelopeOpenIcon className="m-3 w-16 p-3 text-blue-300" />
          </div>
        </div>
        <p>
          It looks like this is the first time you try to log in. You will need
          to activate and set a password for your account first.
        </p>
        <br />
        <p>
          An e-mail has been sent to: <code>{email}</code>
        </p>
        <p className="mt-2">
          Please <strong>follow the instructions in the e-mail</strong>.
        </p>
      </div>
    </CenteredLayout>
  );
}

export default function Login() {
  const loaderData = useLoaderData<typeof loader>();
  const data = useActionData<typeof action>();
  const transition = useNavigation();
  const [errorHidden, setErrorHidden] = useState(false);
  // Login does not rerender on submit. So we need to unset this if the state changes (then a possible error can appear again)
  useEffect(() => setErrorHidden(false), [transition.state]);

  const hideError = () => setErrorHidden(true);

  const actionTarget = [
    `/auth/login`,
    loaderData.search ? `?${loaderData.search}` : "",
  ].join("");
  const redirect = data && "redirect" in data ? data.redirect : loaderData.redirect;

  if (data && "showWelcome" in data) {
    return <Welcome email={data.email} />;
  }

  return (
    <CenteredLayout className="animate-[blur-in_0.3s_ease_forwards]">
      {loaderData.showDevWarning && <DevWarning />}
      <div className="my-8 lg:my-10">
        {loaderData.appRedirect ? (
          <AppLoginNote app={loaderData.appRedirect} />
        ) : (
          <>
            <h2 className="text-3xl">Log In …</h2>
            <div className="opacity-70">
              … to access your projects and hydra tools.
            </div>
          </>
        )}
      </div>
      <div className="relative m-6">
        {data && "provider" in data ? (
          data.provider === LoginProvider.HYDRA_GITLAB ? (
            <GitlabLogin email={data.email} redirect={redirect} />
          ) : (
            <PasswordLogin
              email={data.email}
              onInput={hideError}
              redirect={redirect}
            />
          )
        ) : (
          <Form method="post" replace action={actionTarget}>
            {redirect && (
              <input type="hidden" name="redirect" value={redirect} />
            )}
            <input type="hidden" name="_action" value="query" />
            <div className="relative">
              <StyledTextInput
                type="email"
                name="email"
                id="login-email"
                placeholder="E-mail address"
                required
              />
            </div>
            <div className="my-4"></div>
            <button
              type="submit"
              disabled={transition.state !== "idle"}
              className="flex w-full items-center
                justify-center rounded border border-blue-400 bg-blue-500 p-2 px-5 pl-7 text-white shadow-lg shadow-blue-300/30
                ring-blue-900 hover:bg-blue-600 focus:outline-none focus-visible:ring-2
                active:bg-blue-400
                disabled:border-blue-100 disabled:bg-blue-200
              "
            >
              Continue
            </button>
          </Form>
        )}
        <Transition
          className="absolute z-10 w-full"
          show={transition.state === "idle" && !errorHidden}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          {data && "error" in data && data.error && (
            <div className="my-3 inline-flex items-center justify-center rounded-full border border-red-300 bg-red-50 px-3 text-center text-red-500">
              <ExclamationCircleIcon className="mr-2 w-4" />
              <span>{data.error}</span>
            </div>
          )}
        </Transition>
      </div>
    </CenteredLayout>
  );
}

export { DefaultErrorBoundary as ErrorBoundary } from "~/components/core";
